// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    APP_ENVIRONMENT: 'https://xpos.xcommerce.eu/XPosServices/public/api/',
    PDF_REPORTS_ENDPOINT: 'http://xpos.xcommerce.eu/XPosServices/public/reports/',
    PRODUCT_ENDPOINT: 'product/',
    STATION_ENDPOINT: 'station/',
    ENDPOINT_REQUEST_MAX_ATTEMPTS: 10,
    NEXT_REQUEST_ATTEMPT_DELAY: 3000, // in miliseconds
    CUSTOMER_ENDPOINT: 'customer/',
    CUSTOMER_SEARCH_ENDPOINT: 'search/',
    CUSTOMER_SAVE_ENDPOINT: 'save',
    COUNTRIES_ENDPOINT: 'country',
    ORDER_SAVE_ENDPOINT: 'orders/save',
    ORDER_DELETE_ENDPOINT: 'orders/delete',
    SEND_ORDER_CONFIRMATION_EMAIL_ENDPOINT: 'orders/sendOrderConfirmationEmail',
    PAYMENT_SAVE_ENDPPINT: 'payment/save',
    REPORTS_ENDPOINT: 'salesreport',
    REPORTS_PRODUCTS_ENDPOINT: 'salesreport/products',
    END_OF_DAY_REPORT: 'endofdayreport',
    SHOPS_ENDPOINT: 'shops',
    TERMINALS_ENDPOINT: 'terminals',
    STATIONS_ENDPOINT: 'stations',
    USER_ENDPOINT: 'user',
    CONFIGURATION_ENDPOINT: 'configuration',
    WEBSHOP_CONFIGURATION_ENDPOINT: 'configuration/webshop/list',
    AGENTS_ENDPOINT: 'agents',
    RECEIPT_PRINTERS_ENDPOINT: 'receipt-printers',
    AGENT_ACCESS_PAGES: ['/pos', '/reports', '/settings', '/order-quote-preview'],
    MOLLIE_PAYMENT_SAVE_ENDPOINT: 'mollie/payment/create',
    MOLLIE_PAYMENT_CANCEL_ENDPOINT: 'mollie/payment/cancel/',
    PAYMENT_SERVICE_PROVIDER: 'mollie', // this should be mollie or adyen,
    PAYMENT_GET_ENDPOINT: 'payment/get/',
};

export const adyen_payment_status = {
    PAYMENT_COMPLETE: "success",
};

export const mollie_payment_status = {
    CANCELED: "canceled",
    EXPIRED: "expired",
    FAILED: "failed",
    OPEN: "open",
    PAID: "paid",
};

export const payment_method_type = {
    CARD_PAYMENT_TYPE: "card",
    CASH_PAYMENT_TYPE: "cash",
    SPLIT_PAYMENT_TYPE: "split",
};

export const general_configuration = {
    INPUT_FIELD_MAX_LENGTH: 15
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
